import React, {useState} from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import * as styles from './styles/main.module.scss'
import * as contactStyle from '../components/contact/contact.module.scss'
import emailjs from 'emailjs-com';
import { changeColor } from "../source/changeColor"
import { useForm } from 'react-hook-form'
import { Helmet } from "react-helmet"



const Kontakt = ({data}) => {
        const { register, handleSubmit, formState: {errors}} = useForm();
        const allAirtableBanner = data.allAirtable.edges[0].node.data;
        const contactAirtable = allAirtableBanner;
        
        const [formSend, setFormSend] = useState({
            send: false
        });

        const onSubmit = (e,data) => {
    
            data.target.parentNode.classList.add('sending');
           
            emailjs.sendForm('service_n523nod', 'template_epjqztw', data.target, 'user_Qm5a9nDNoThYMhoHjl2Sv')
            .then((result) => {
                data.target.parentNode.classList.remove('sending');
                setFormSend({
                    send: true
                })
                data.target.reset();
            }, (error) => {
                console.log(error.text);
            });
        
            
        };

  return (
      <>
      <Helmet>
       <meta name='description' content='Jeśli zainteresowała Państwa nasza oferta, bądź chcieliby Państwo uzyskać szczegółowe informacje na temat oferowanych przez nas usług - zapraszamy do kontaktu'/>
    </Helmet>
    <Layout pageTitle="Kontakt">
        <div className={styles.firstSection}>
            <div className={`${styles.container}`}>
                <div className={`${styles.sectionTitle}`}>
                    <h1>{changeColor(contactAirtable.contactText,2)}</h1>
                    </div>

                <div className={contactStyle.contactForms}>

                    <div className={contactStyle.address}>
                        <h2>{contactAirtable.nazwaFirmy}</h2>
                        <p>{contactAirtable.nazwaFirmy2}</p>

                        <div className={contactStyle.street}>
                            <p>{contactAirtable.ulica}</p>
                            <p>{contactAirtable.kodPocztowy}</p>
                        </div>
                        <div className={contactStyle.phone}>
                            <a href="tel:627390065">
                                {contactAirtable.telefon}
                            </a>
                            <a href="tel:627390084">
                                {contactAirtable.telefon2}
                            </a>
                            <a href="tel:603969322">
                                {contactAirtable.telefon3}
                            </a>
                        </div>
                        <div className={contactStyle.mail}>
                            <a href="mailto:biuro.aliatan@wp.pl">{contactAirtable.email}</a>
                            <a href="mailto:biuro.aliatankadry@wp.pl">{contactAirtable.email2}</a>
                        </div>

                        <div className={contactStyle.open}>
                                <p className={contactStyle.bold}>Godziny otwarcia:</p>
                                <p>od poniedziałku do piątku</p>
                                <p>7:30 - 15:30</p>
                        </div>

                        <p><strong>Informacje dodatkowe:</strong></p>
                        <p>- dogodna lokalizacja</p>
                        <p>- bezpłatny parking</p>
                    </div>

                    <div className={contactStyle.formContact} >
                        {formSend.send ? <div className={contactStyle.alertSucess}>Wiadomość została wyslana</div> :
                        <form onSubmit={handleSubmit(onSubmit)}>
                            
                            
                            <div className={contactStyle.inputBlock}>
                                <label htmlFor="name">Nazwa firmy / imię nazwisko</label>
                                <input 
                                    id="name"
                                    name="name"
                                    {...register("name",{
                                        required: {
                                            value: true
                                        }
                                    })}
                                    placeholder="Wpisz nazwę firmy lub imię nazwisko"
                                    className={`textBlock ${errors.name? 'alert-error' : ''}`}
                                />
                                {errors.name && <span className="error">{errors.name.message}</span>}
                            </div>

                            <div className={contactStyle.inputBlock}>
                                <label htmlFor="email">Email</label>
                                <input 
                                    id="email"
                                   
                                    name="email"
                                    {...register("email",{ 
                                        required : {
                                            value: true
                                        },
                                        pattern:{
                                            value: /\S+@\S+\.\S+/
                                        }
                                })}
                                    placeholder="Wpisz swój adres email"
                                    className={`textBlock ${errors.email? 'alert-error' : ''}`}
                                />
                                <span className="error">{errors.email && errors.email.message }</span>
                                
                            </div>
                            
                            <div className={contactStyle.inputBlock}>
                                <label htmlFor="messageText">Treść</label>
                                <textarea 
                                    id="messageText"
                                    name="messageText"
                                    placeholder="Wpisz treść wiadomości"
                                    {...register("messageText",{
                                        required: {
                                            value: true
                                        }
                                    })}
                                    className={`textBlock textarea ${errors.messageText? 'alert-error' : ''}`}
                                />
                                <span className="error">{errors.messageText && errors.messageText.message }</span>
                                
                            </div>

                            <div className={contactStyle.inputSubmit}>
                                <input type='submit' value='Wyślij' className={contactStyle.submitBtn} />
                            </div>

                        </form>
                        }
                    </div>

                </div>
                <div className={contactStyle.googleMap}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9912.686490550568!2d18.0854501!3d51.6017439!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf4229245229cc3f0!2sBiuro%20Rachunkowe%20Aliatan!5e0!3m2!1spl!2spl!4v1630355822150!5m2!1spl!2spl" width="600" height="450" allowfullscreen="" loading="lazy" title="googleMap"></iframe>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default Kontakt


export const query = graphql `
{
    allAirtable(sort: {fields: data___Ids}, filter: {table: {eq: "Kontakt"}}) {
      edges {
        node {
          data {
            Ids
            contactText
            nazwaFirmy
            nazwaFirmy2
            ulica
            kodPocztowy
            telefon
            telefon2
            telefon3
            email
            email2
          }
        }
      }
    }
  }`
// extracted by mini-css-extract-plugin
export var contactForms = "contact-module--contactForms--2JXGk";
export var address = "contact-module--address--3A8Yq";
export var street = "contact-module--street--1ftz2";
export var phone = "contact-module--phone--3TtAt";
export var mail = "contact-module--mail--hdji8";
export var open = "contact-module--open--1iBDU";
export var bold = "contact-module--bold--2C9kF";
export var formContact = "contact-module--formContact--es3aN";
export var inputBlock = "contact-module--inputBlock--28GWg";
export var inputSubmit = "contact-module--inputSubmit--luUJi";
export var submitBtn = "contact-module--submitBtn--1CPpi";
export var alertSucess = "contact-module--alertSucess--23sjB";
export var googleMap = "contact-module--googleMap--3U2ZK";